import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import Dashboard from "../../components/dashboard/dashboard.component";

const DashboardPage = () => {
  return (
    <Fragment>
      <Breadcrumb title="Dashboard" noTitle />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <Dashboard />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DashboardPage;
