import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";

const SignupSuccess = () => {
  return (
    <Fragment>
      <Breadcrumb title="Success" parent="Signup" noTitle />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Signup Success!</h5>
                <span>We have created your account.</span>
              </div>
              <div className="card-body">
                <p>
                  Please check your inbox and verify yourself. After that you
                  can immediately start your journey. You can even choose which
                  button to use!
                </p>
                <button
                  className="btn btn-primary mr-1 btn-square"
                  type="button"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Fake email verification
                </button>{" "}
                <button
                  className="btn btn-secondary mr-1 btn-square"
                  type="button"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Fake email verification
                </button>{" "}
                <button
                  className="btn btn-danger mr-1 btn-square"
                  type="button"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Fake email verification
                </button>{" "}
                <button
                  className="btn btn-success mr-1 btn-square"
                  type="button"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Fake email verification
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignupSuccess;
