// hook by: https://usehooks.com/useAuth/
import React, { useState, useEffect, useContext, createContext } from "react";
import fireconf, { fdb } from "../firebase/config";

import LogRocket from "logrocket";

// import Loader from "../../components/common/loader";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const [organizationProfile, setOrganizationProfile] = useState(false);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    return fireconf
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
        return response.user;
      });
  };

  const signup = (email, password) => {
    return fireconf
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
        return response.user;
      });
  };

  const signout = () => {
    return fireconf
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  const sendPasswordResetEmail = (email) => {
    return fireconf
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return fireconf
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  // TO DO: For new sign up gaat dit te snel. Kan dit langzamer?
  // Probleem is dat bij het aanmelden van een nieuw account het UID al wel bekend is. De auth USER bestaat al
  // Echter is de extra data in het userPRofile nog nét niet verwerkt. Dus is "user" met uid nog niet te
  // vinden in de collectie.
  const getUserProfileData = async (uid) => {
    try {
      const userProfileData = fdb.collection("users").doc(uid);
      userProfileData.get().then((user) => {
        if (user.exists) {
          const userData = user.data({ serverTimestamps: "estimate" }); // hier gaat iets fout.

          setUserProfile(userData);
          getOrganizationProfileData(userData.organizationID); // issue shows itself

          LogRocket.identify(uid, {
            name: `${userData.firstName} ${userData.lastName}`,
            email: userData.email,
            organization: userData.organization,
          });
          // console.log("useAuth: getuserProfileData: Loaded user Profile data.");
        } else {
          // console.log("useAuth: getuserProfileData: user doesn't exist yet");
        }
      });
    } catch (err) {
      // console.log("useAuth: getuserProfileData: try catched error");
      console.log(err.message);
    }
  };

  const getOrganizationProfileData = async (clientID) => {
    try {
      const organizationSnapshot = fdb
        .collection("organizations")
        .doc(clientID);
      organizationSnapshot.get().then((org) => {
        setOrganizationProfile(org.data({ serverTimestamps: "estimate" }));
        // console.log(
        //   "useAuth: getuserProfileData: getOrganizationProfileData: Loaded organization data."
        // );
      });
    } catch (err) {
      console.error(
        "useAuth: getuserProfileData: getOrganizationProfileData: Error loading org data: ",
        err.message
      );
    }
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = fireconf.auth().onAuthStateChanged((user) => {
      if (user) {
        // console.log("UseAuth: UseEffect: ", user);
        setUser(user);
        getUserProfileData(user.uid);

        try {
        } catch (err) {
          console.error(err.message);
        }
      } else {
        setUser(false);
        setUserProfile(false);
        // console.log("user logged out.");
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    userProfile,
    organizationProfile,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
}
