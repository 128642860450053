import React, { Fragment } from "react";
import { ToastContainer, toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import Breadcrumb from "../../components/common/breadcrumb";

import { useAuth } from "../../data/hooks/useAuth.hook";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_live_ln3q3ksNiMtmX5DZiOOOa33R008itDYpdr");

const NewCredits = () => {
  const auth = useAuth();

  const handleClickSmall = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1HYtGzJQuI0M02Js2lzwLVeP", // Replace with the ID of your price
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl: "https://app.readingrooster.com",
      cancelUrl: "https://app.readingrooster.com",
      customerEmail: auth.userProfile.email,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.

    if (error) {
      toast.error(error.message);
    }
  };

  const handleClickMedium = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1HYtGuJQuI0M02JszyVEkkEu", // Replace with the ID of your price
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl: "https://app.readingrooster.com",
      cancelUrl: "https://app.readingrooster.com",
      customerEmail: auth.userProfile.email,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.

    if (error) {
      toast.error(error.message);
    }
  };

  const handleClickLarge = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1HYtGkJQuI0M02Js9ze02myj", // Replace with the ID of your price
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl: "https://app.readingrooster.com",
      cancelUrl: "https://app.readingrooster.com",
      customerEmail: auth.userProfile.email,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.

    if (error) {
      toast.error(error.message);
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Pricing" parent="Pricing" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Packages you can choose</h5>
                <span>
                  When clicking on any buy button you are directed to the stripe
                  payment page.
                </span>
              </div>
              <div className="card-body pricing-card-design-3">
                <div className="row pricing-content-ribbons">
                  <div className="col-md-4">
                    <div className="pricing-block card text-center">
                      <svg x="0" y="0" viewBox="0 0 360 220" fill="#4466f2">
                        <g>
                          <path d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061 c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                          s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48 L0.732,193.75z"></path>
                        </g>
                        <text
                          transform="matrix(1 0 0 1 105 116.2686)"
                          fill="#fff"
                          fontSize="78.4489"
                        >
                          €25
                        </text>
                        {/* 
                        <text
                          transform="matrix(0.9566 0 0 1 197.3096 83.9121)"
                          fill="#fff"
                          fontSize="29.0829"
                        >
                          .00
                        </text>
                        <text
                          transform="matrix(1 0 0 1 233.9629 115.5303)"
                          fill="#fff"
                          fontSize="15.4128"
                        >
                                                  /one time
                        </text>
                                              */}
                      </svg>
                      <div className="pricing-inner">
                        <h3 className="font-primary">Small</h3>
                        <ul className="pricing-inner">
                          <li>
                            <h6>
                              <b>500</b> credits
                            </h6>
                          </li>
                          <li>
                            <h6>
                              <b>€ 0,05</b> per credit
                            </h6>
                          </li>
                          <li>
                            <h6>
                              <b>Unlimited</b> projects
                            </h6>
                          </li>

                          <li>
                            <h6>
                              <b>5</b> domains per project
                            </h6>
                          </li>
                          <li>
                            <h6>
                              <b>Forever</b> valid credits
                            </h6>
                          </li>
                        </ul>
                        <button
                          className="btn btn-primary btn-lg"
                          type="button"
                          role="link"
                          onClick={handleClickSmall}
                        >
                          Get Credits
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="pricing-block card text-center">
                      <div className="ribbon ribbon-clip-right ribbon-right ribbon-danger">
                        Popular
                      </div>
                      <svg x="0" y="0" viewBox="0 0 360 220" fill="#4466f2">
                        <g>
                          <path d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061                                          c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                          s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48                                          L0.732,193.75z"></path>
                        </g>
                        <text
                          transform="matrix(1 0 0 1 105 116.2686)"
                          fill="#fff"
                          fontSize="78.4489"
                        >
                          €50
                        </text>
                        {/*
                        <text
                          transform="matrix(0.9566 0 0 1 197.3096 83.9121)"
                          fill="#fff"
                          fontSize="29.0829"
                        >
                          .00
                        </text>
                        <text
                          transform="matrix(1 0 0 1 233.9629 115.5303)"
                          fill="#fff"
                          fontSize="15.4128"
                        >
                                                  /one time
                        </text>
                        */}
                      </svg>
                      <div className="pricing-inner">
                        <h3 className="font-primary">Medium</h3>
                        <ul className="pricing-inner">
                          <li>
                            <h6>
                              <b>1.250</b> credits
                            </h6>
                          </li>
                          <li>
                            <h6>
                              <b>€ 0,04</b> per credit
                            </h6>
                          </li>
                          <li>
                            <h6>
                              <b>Unlimited</b> projects
                            </h6>
                          </li>

                          <li>
                            <h6>
                              <b>5</b> domains per project
                            </h6>
                          </li>
                          <li>
                            <h6>
                              <b>Forever</b> valid credits
                            </h6>
                          </li>
                        </ul>
                        <button
                          className="btn btn-outline-success btn-lg"
                          type="button"
                          role="link"
                          onClick={handleClickMedium}
                        >
                          Get Credits
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="pricing-block card text-center">
                      <svg x="0" y="0" viewBox="0 0 360 220" fill="#4466f2">
                        <g>
                          <path d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061                                          c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                          s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48                                          L0.732,193.75z"></path>
                        </g>
                        <text
                          transform="matrix(1 0 0 1 85 116.2686)"
                          fill="#fff"
                          fontSize="78.4489"
                        >
                          €100
                        </text>
                        {/*
                        <text
                          transform="matrix(0.9566 0 0 1 244 83.9121)"
                          fill="#fff"
                          fontSize="29.0829"
                        >
                          .00
                        </text>
                        <text
                          transform="matrix(1 0 0 1 244 115.5303)"
                          fill="#fff"
                          fontSize="15.4128"
                        >
                                                  /one time
                        </text>
                        */}
                      </svg>
                      <div className="pricing-inner">
                        <h3 className="font-primary">Large</h3>
                        <ul className="pricing-inner">
                          <li>
                            <h6>
                              <b>3.333</b> credits
                            </h6>
                          </li>
                          <li>
                            <h6>
                              <b>€ 0,03</b> per credit
                            </h6>
                          </li>
                          <li>
                            <h6>
                              <b>Unlimited</b> projects
                            </h6>
                          </li>

                          <li>
                            <h6>
                              <b>5</b> domains per project
                            </h6>
                          </li>
                          <li>
                            <h6>
                              <b>Forever</b> valid credits
                            </h6>
                          </li>
                        </ul>
                        <button
                          className="btn btn-primary btn-lg"
                          type="button"
                          role="link"
                          onClick={handleClickLarge}
                        >
                          Get Credits
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>How does it work?</h5>
                <span>Examples of what you can do with a credit</span>
              </div>
              <div className="card-body">
                <p>
                  Every credit stands for a crawl of a keyword. So for example
                  when you want to crawl 100 keywords every week, this will
                  count 4 * 100 for a total of 400 credits in a month.
                </p>

                <p>
                  You are free to use the credits however you want. Every
                  project can have as many keywords as you need. Just remember 1
                  crawl for 1 keywords count as 1 credit.
                </p>

                <p>
                  Do you need both data for mobile and desktop rankings? This
                  will count for 2 credits. Tip: Set up 2 projects, one for
                  desktop and one for mobile. This keeps everything nicely
                  organized when you report.
                </p>

                <p>
                  Do you need ranking data of a keyword ranking in the UK &
                  United States? This will count for 2 credits. Tip: Set up 2
                  projects, one for the US and one for the UK. This keeps
                  everything nicely organized when you report.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default NewCredits;
