import React, { Fragment, useState, useEffect } from "react";
// import man from "../../../assets/images/dashboard/user.png";
import { Link } from "react-router-dom";
import { Edit, Loader } from "react-feather";

import fireconf from "../../../data/firebase/config";
// data imports
import { useAuth } from "../../../data/hooks/useAuth.hook";
// import useFirestoreDoc from "../../../data/hooks/useFirestoreDoc.hook.jsx"; // not using this

const UserPanel = () => {
  const fdb = fireconf.firestore();
  const auth = useAuth();
  const [orgData, setOrgData] = useState({});

  useEffect(() => {
    if (auth.userProfile) {
      const unsubscribe = fdb
        .collection("organizations")
        .doc(auth.userProfile.organizationID)
        .onSnapshot((snap) => {
          const data = snap.data();
          setOrgData(data);
        });

      return () => unsubscribe();
    }
  }, [fdb, auth.userProfile]);

  // const getOrgDataFromFirestore = async () => {
  //   try {
  //     fdb
  //       .collection("organizations")
  //       .doc("djseFuzmGCS1obJ98YQo")
  //       .onSnapshot((doc) => {
  //         console.log("data: ", doc.data());
  //         orgDataArray.push(doc.data());
  //       });
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  return (
    <Fragment>
      <div className="sidebar-user text-center">
        <div>
          {" "}
          {auth.userProfile ? (
            <Fragment>
              <img
                className="img-60 rounded-circle lazyloaded blur-up"
                src={auth.userProfile.profileIMG}
                alt="#"
              />
              <div className="profile-edit">
                <Link to="#">
                  <Edit />
                </Link>
              </div>
            </Fragment>
          ) : (
            <Loader color="white" />
          )}
        </div>
        <h6 className="mt-3 f-14">
          {auth.userProfile && auth.userProfile.firstName}
        </h6>
        <p>
          {auth.userProfile && auth.userProfile.organization} | Credits:{" "}
          {auth.organizationProfile && orgData.credits}
        </p>
      </div>
    </Fragment>
  );
};

export default UserPanel;
