import React from "react";

const DataCard = (props) => {
  
  const cardName = props.cardName;

  return (
    <div className="card">
      <div className="card-header">
        <h5>{cardName}</h5>
      </div>
      <div className="card-body">
        <div className="user-status table-responsive">
          <table className="table table-bordernone">
            <thead>
              <tr>
                <th scope="col">Details</th>
                <th scope="col">Quantity</th>
                <th scope="col">Status</th>
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Simply dummy text of the printing</td>
                <td className="digits">1</td>
                <td className="font-primary">Pending</td>
                <td className="digits">$6523</td>
              </tr>
              <tr>
                <td>Long established</td>
                <td className="digits">5</td>
                <td className="font-secondary">Cancle</td>
                <td className="digits">$6523</td>
              </tr>
              <tr>
                <td>sometimes by accident</td>
                <td className="digits">10</td>
                <td className="font-secondary">Cancle</td>
                <td className="digits">$6523</td>
              </tr>
              <tr>
                <td>Classical Latin literature</td>
                <td className="digits">9</td>
                <td className="font-primary">Return</td>
                <td className="digits">$6523</td>
              </tr>
              <tr>
                <td>keep the site on the Internet</td>
                <td className="digits">8</td>
                <td className="font-primary">Pending</td>
                <td className="digits">$6523</td>
              </tr>
              <tr>
                <td>Molestiae consequatur</td>
                <td className="digits">3</td>
                <td className="font-secondary">Cancle</td>
                <td className="digits">$6523</td>
              </tr>
              <tr>
                <td>Pain can procure</td>
                <td className="digits">8</td>
                <td className="font-primary">Return</td>
                <td className="digits">$6523</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DataCard;
