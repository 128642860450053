import React from "react";

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 footer-copyright">
            <p className="mb-0">
              Copyright 2020 ©{" "}
              <a
                href="https://www.readingrooster.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                Reading Rooster{" "}
              </a>
              - All rights reserved.
            </p>
          </div>
          <div className="col-md-6">
            <p className="pull-right mb-0">
              Made with
              <i className="fa fa-coffee"></i> by{" "}
              <a
                href="https://www.linkedin.com/in/michelkant/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Michel kant
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
