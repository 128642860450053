import React, { Fragment, useState, useEffect, useMemo } from "react";
import Breadcrumb from "../common/breadcrumb";

import TableContainer from "./TableContainer.component";
import { SelectColumnFilter } from "./Filter.component";

const TableTest = () => {

  const [data, setData] = useState([]);
  
  useEffect(() => {
    const doFetch = async () => {
      const response = await fetch("https://randomuser.me/api/?results=100");
      const body = await response.json();
      const contacts = body.results;
      console.log("contacts", contacts);
      setData(contacts);
    };
    doFetch();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "name.title",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "First Name",
        accessor: "name.first",
      },
      {
        Header: "Last Name",
        accessor: "name.last",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "City",
        accessor: "location.city",
        disableFilters: true,
      },
    ],
    []
  );

  return (
    <Fragment>
      <Breadcrumb title="Project Overview" parent="Table Test Page" noTitle />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Project Overview</h5>
                <span>
                  {" "}
                  Showing all <code>projects</code> associated to client: you
                </span>
              </div>

              <div className="table-responsive table-hover">
                <TableContainer columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TableTest;
