import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Home, Activity, Users } from "react-feather";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import HomeComponent from "./overview.project.component";
import BudgetComponent from "./budget.project.component";
import UserComponent from "./user.project.component";

const ProjectPage = (props) => {
  const projectID = props.match.params.id;
  return (
    <Fragment>
      <Breadcrumb title="Project Details" id={projectID} parent="Dashboard" />
      <div className="container-fluid">
        <div className="row theme-tab">
          <Tabs className="col-sm-12">
            <TabList className="tabs tab-title">
              <Tab className="current">
                <Home />
                Overview
              </Tab>
              <Tab>
                <Activity />
                Budget Summary
              </Tab>
              <Tab>
                <Users />
                Team Members
              </Tab>
            </TabList>
            <div className="tab-content-cls">
              <TabPanel>
                <HomeComponent />
              </TabPanel>
              <TabPanel>
                <BudgetComponent />
              </TabPanel>
              <TabPanel>
                <UserComponent />
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </Fragment>
  );
};

export default ProjectPage;
