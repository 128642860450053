import React, { useState } from "react";

import ProjectEditModal from "../../modals/projectEdit.modal";

const DataCardProjectSettings = (props) => {
  const projectName = props.projectName;
  const projectMetaData = props.projectMetaData;
  const projectScrapeDetails = props.projectScrapeDetails;
  const cardName = props.cardName;
  const projectID = props.projectID;

  const [settingsModal, setSettingsModal] = useState();
  const toggleSettingsModal = () => {
    setSettingsModal(!settingsModal);
  };

  function capitalizeFirstLetter(string) {
    let capitalizedString = "";
    if (string === undefined) {
      return;
    } else {
      capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
    }
    return capitalizedString;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5>{cardName}</h5>
      </div>
      <div className="card-body">
        <div className="user-status table-responsive">
          <table className="table table-bordernone">
            <thead>
              <tr>
                <th scope="col">Setting</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Project Name: </td>
                <td className="font-secondary">{projectName}</td>
              </tr>
              <tr>
                <td>Project ID: </td>
                <td className="font-secondary">{projectID}</td>
              </tr>
              <tr>
                <td>Location & Language: </td>
                <td className="font-secondary">
                  {capitalizeFirstLetter(projectMetaData.location)} /{" "}
                  {capitalizeFirstLetter(projectMetaData.language)}
                </td>
              </tr>
              <tr>
                <td>Device & OS: </td>
                <td className="font-secondary">
                  {capitalizeFirstLetter(projectMetaData.device)} /{" "}
                  {capitalizeFirstLetter(projectMetaData.os)}
                </td>
              </tr>
              <tr>
                <td>Scrape Frequency: </td>
                <td className="font-secondary">
                  {capitalizeFirstLetter(projectScrapeDetails.scrapeFrequency)}
                </td>
              </tr>
              {projectScrapeDetails.scrapeFrequency === "weekly" && (
                <tr>
                  <td>Scrape Day of the Week: </td>
                  <td className="font-secondary">
                    {capitalizeFirstLetter(
                      projectScrapeDetails.scrapeDayOfTheWeek
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer">
        <div className="col-sm-9 offset-sm-3">
          <button
            className="btn btn-square btn-outline-info btn-sm float-right"
            type="submit"
            onClick={toggleSettingsModal}
          >
            Change settings
          </button>
          <ProjectEditModal
            projectName={projectName}
            projectID={projectID}
            projectMetaData={projectMetaData}
            projectScrapeDetails={projectScrapeDetails}
            settingsModal={settingsModal}
            setSettingsModal={setSettingsModal}
          />
        </div>
      </div>
    </div>
  );
};

export default DataCardProjectSettings;
