import React, { useState, useEffect, Fragment } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "react-query";
// import { ReactQueryDevtools } from "react-query-devtools"; // not required for live
import { useAuth } from "../../data/hooks/useAuth.hook";
import { firebaseFunctions } from "../../data/firebase/config";
import { useHistory } from "react-router-dom";
import fireconf from "../../data/firebase/config";

const NewProjectForm = () => {
  const fdb = fireconf.firestore();

  const { register, handleSubmit, watch, errors, control } = useForm({
    defaultValues: {
      domainstToTrack: [{ domainName: "https://www.michelkant.nl" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "domainstToTrack",
  });

  const auth = useAuth();
  const history = useHistory();

  const [stateScrapeLocationValue, setScrapeLocationValue] = useState("");
  const [stateScrapeLanguageValue, setScrapeLanguageValue] = useState("");
  const [stateScrapeFrequency, setScrapeFrequency] = useState("");
  const [stateRunScrapeNow, setRunScrapeNow] = useState(false);
  const [countriesList, setCountriesList] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  const errorStyle = {
    color: "#ff5370",
  };

  // not using this as i'm now loading data from firebase directly
  // const fetchLocations = async () => {
  //   const res = await fetch(
  //     "https://europe-west1-reading-rooster-tracker.cloudfunctions.net/fetchScrapeLocations"
  //   );
  //   console.log("Actually fetched location the data from cloud function");
  //   return res.json();
  // };

  // const { data: locData, status: locStatus } = useQuery(
  //   "locations",
  //   fetchLocations,
  //   {
  //     staleTime: 1000 * 60 * 60 * 24, // fresh time is 24 hours one hour as this data barely refreshes
  //     cacheTime: 1000 * 60 * 60 * 24, // cache time is 24 hours as this data barely refreshes
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //     onError: () =>
  //       toast.error("Something went wrong. Please reload the page."),
  //   }
  // );

  const fetchLanguages = async () => {
    const res = await fetch(
      "https://us-central1-reading-rooster-tracker.cloudfunctions.net/fetchScrapeLanguages"
    );
    console.log("Actually fetched language data from cloud function");
    return res.json();
  };

  const { data: lanData, status: lanStatus } = useQuery(
    "languages",
    fetchLanguages,
    {
      staleTime: 1000 * 60 * 60 * 24, // fresh time is 24 hours one hour as this data barely refreshes
      cacheTime: 1000 * 60 * 60 * 24, // cache time is 24 hours as this data barely refreshes
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: () =>
        toast.error("Something went wrong. Please reload the page."),
    }
  );

  const textAreaToArray = (keywords) => {
    const dirtyKeywordArray = keywords.split(/\r?\n/);
    const cleanArray = dirtyKeywordArray.filter((item) => item !== "");
    return cleanArray;
  };

  // console.log("auth", auth);

  const onSubmit = (data) => {
    if (data !== "") {
      setSubmitting(true);

      // setting keywords to track in to array
      const textAreaAsArray = textAreaToArray(data.keywordsToTrackRaw);
      data.locationActual = stateScrapeLocationValue;
      data.languageActual = stateScrapeLanguageValue;
      data.clientID = auth.userProfile.organizationID;
      data.keywordsToTrack = textAreaAsArray;
      data.nrkeywords = textAreaAsArray.length;
      data.createdBy = auth.user.uid;
      data.hasAccess = [];
      data.runTimes = 0;
      delete data["keywordsToTrackRaw"];

      data.hasAccess.push(auth.userProfile.apiKey);
      console.log("data", data);

      // cleaning domains in case empty ones were left open
      data.domainsToTrack = data.domainsToTrack.filter((domain) => {
        return domain !== undefined;
      });

      const addProject = firebaseFunctions.httpsCallable("newProjectDirector");
      let newProjectID;

      addProject(data)
        .then((result) => {
          console.log(result);
          if (result.data.success === true) {
            newProjectID = result.data.projectID;
            toast.success(result.data.message);
            setSubmitting(false);
          }
          if (result.data.success === false) {
            console.log(result);
            toast.error(result.data.message);
          }

          // go to project page if project is created succesfully
          if (newProjectID) {
            console.log("redirecting...");
            history.push(
              `${process.env.PUBLIC_URL}/project/id/${newProjectID}`
            );
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      errors.showMessages();
    }
  };

  useEffect(() => {
    let allCountries = [];

    if (countriesList.length < 1) {
      // get all countries data from firebase
      // TODO: Can i cache this data? useMemo or useCallback maybe?
      fdb
        .collection("countries")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            allCountries.push(doc.data());
          });
        })
        .then(() => {
          setCountriesList(allCountries);
          console.log("downloaded all countries");
        });
    }

    setScrapeFrequency(watch("scrapeFrequency"));
    setRunScrapeNow(watch("runScrapeNow"));
  }, [watch]);

  // trigger validation at the field array level
  // useEffect(() => {
  //   if (fields) trigger("name"); // with resolver
  //   if (!fields.length) setError("minLength", "at least 1 field array item"); // build-in validator
  // }, [trigger, fields]);

  return (
    <div className="card">
      <div className="card-header">
        <h5>Create new Rank Tracking project</h5>
      </div>
      <form
        className="form theme-form"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label pt-0">
                  Organisation name:
                </label>
                <div className="col-sm-9">
                  <div className="form-control-static">
                    {auth ? auth.userProfile.organization : "Loading..."}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label pt-0">
                  Organisation ID:
                </label>
                <div className="col-sm-9">
                  <div className="form-control-static">
                    {auth ? auth.userProfile.organizationID : "Loading..."}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="projectName"
                  className="col-sm-3 col-form-label"
                >
                  Project Name:
                </label>
                <div className="col-sm-9">
                  <input
                    id="projectName"
                    name="projectName"
                    className="form-control"
                    type="text"
                    ref={register({
                      required: {
                        value: true,
                        message: "The project really needs a name :)",
                      },
                      pattern: {
                        value: /^[A-Za-z0-9- ]+$/i,
                        message: "Alphabetical characters and numbers only.",
                      },
                      minLength: {
                        value: 3,
                        message:
                          "Please make sure the project has a name of at least 3 characters.",
                      },
                      maxLength: {
                        value: 35,
                        message:
                          "Do you really need more than 35 charachters for your project name?",
                      },
                    })}
                  />
                  <span style={errorStyle}>
                    {errors.projectName && errors.projectName.message}
                  </span>
                  <div className="valid-feedback">Looks good!</div>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="scrapeLocation"
                  className="col-sm-3 col-form-label"
                >
                  Scrape Location:
                </label>
                <div className="col-sm-9">
                  <select
                    id="scrapeLocation"
                    name="scrapeLocation"
                    defaultValue="2528"
                    className="form-control"
                    onChange={(e) => {
                      let index = e.nativeEvent.target.selectedIndex;
                      let text = e.nativeEvent.target[index].text;
                      setScrapeLocationValue(text);
                    }}
                    ref={register({ required: true })}
                  >
                    {/* 
                    {locStatus === "loading" && (
                      <option value="loading">Loading locations...</option>
                    )}

                    {locStatus === "error" && (
                      <Fragment>
                        <option value="loading">
                          Please reload the page...
                        </option>
                      </Fragment>
                    )}

                    {locStatus === "success" && (
                      <Fragment>
                        {
                          (locData.sort((a, b) => {
                            let locA = a.location_name.toLowerCase();
                            let locB = b.location_name.toLowerCase();
                            if (locA < locB) {
                              return -1;
                            }
                            if (locA > locB) {
                              return 1;
                            }
                            return 0;
                          }),
                          locData.map((location) => (
                            <option
                              key={location.location_code}
                              value={location.location_code}
                            >
                              {location.location_name}
                            </option>
                          )))
                        }
                      </Fragment>
                    )}
                    */}
                    <Fragment>
                      {
                        (countriesList.sort((a, b) => {
                          let locA = a.location_name.toLowerCase();
                          let locB = b.location_name.toLowerCase();
                          if (locA < locB) {
                            return -1;
                          }
                          if (locA > locB) {
                            return 1;
                          }
                          return 0;
                        }),
                        countriesList.map((location) => (
                          <option
                            key={location.location_code}
                            value={location.location_code}
                          >
                            {location.location_name}
                          </option>
                        )))
                      }
                    </Fragment>
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="scrapeLocationLanguage"
                  className="col-sm-3 col-form-label"
                >
                  Scrape Language:
                </label>
                <div className="col-sm-9">
                  <select
                    id="scrapeLocationLanguage"
                    name="scrapeLocationLanguage"
                    defaultValue="nl"
                    className="form-control"
                    onChange={(e) => {
                      let index = e.nativeEvent.target.selectedIndex;
                      let text = e.nativeEvent.target[index].text;
                      setScrapeLanguageValue(text);
                    }}
                    ref={register({ required: true })}
                  >
                    {lanStatus === "loading" && (
                      <option value="loading">Loading languages...</option>
                    )}

                    {lanStatus === "error" && (
                      <Fragment>
                        <option value="loading">
                          Please reload the page...
                        </option>
                      </Fragment>
                    )}

                    {lanStatus === "success" && (
                      <Fragment>
                        {
                          (lanData.sort((a, b) => {
                            let locA = a.language_name.toLowerCase();
                            let locB = b.language_name.toLowerCase();
                            if (locA < locB) {
                              return -1;
                            }
                            if (locA > locB) {
                              return 1;
                            }
                            return 0;
                          }),
                          lanData.map((language) => (
                            <option
                              key={language.language_code}
                              value={language.language_code}
                            >
                              {language.language_name}
                            </option>
                          )))
                        }
                      </Fragment>
                    )}
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="scrapeDevice"
                  className="col-sm-3 col-form-label"
                >
                  Device:
                </label>
                <div className="col-sm-9">
                  <select
                    id="scrapeDevice"
                    name="scrapeDevice"
                    defaultValue="mobile"
                    className="form-control"
                    ref={register({ required: true })}
                  >
                    <option value="mobile">Mobile</option>
                    <option value="desktop">Desktop</option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="deviceOS" className="col-sm-3 col-form-label">
                  Device OS:
                </label>
                <div className="col-sm-9">
                  <select
                    id="deviceOS"
                    name="deviceOS"
                    defaultValue="android"
                    className="form-control"
                    ref={register({ required: true })}
                  >
                    <option value="windows">Windows</option>
                    <option value="macos">Mac OS</option>
                    <option value="ios">iOS</option>
                    <option value="android">Android</option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="scrapeFrequency"
                  className="col-sm-3 col-form-label"
                >
                  Scrape Frequency:
                </label>
                <div className="col-sm-9">
                  <select
                    id="scrapeFrequency"
                    name="scrapeFrequency"
                    defaultValue="manually"
                    className="form-control"
                    ref={register({ required: true })}
                  >
                    <option value="manually">Manually</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>
              </div>

              {stateScrapeFrequency === "weekly" && (
                <div className="form-group row">
                  <label
                    htmlFor="scrapeDayOfTheWeek"
                    className="col-sm-3 col-form-label"
                  >
                    Select which day of the week to get the data:
                  </label>
                  <div className="col-sm-9">
                    <select
                      id="scrapeDayOfTheWeek"
                      name="scrapeDayOfTheWeek"
                      defaultValue="Monday"
                      className="form-control"
                      ref={register({
                        validate: (value) => {
                          if (stateScrapeFrequency === "weekly") {
                            return true;
                          }
                        },
                      })}
                    >
                      <option value="monday">Monday</option>
                      <option value="tuesday">Tuesday</option>
                      <option value="wednesday">Wednesday</option>
                      <option value="thursday">Thursday</option>
                      <option value="friday">Friday</option>
                      <option value="saturday">Saterday</option>
                      <option value="sunday">Sunday</option>
                    </select>
                    <span style={errorStyle}>
                      {errors.scrapeDayOfTheWeek &&
                        errors.scrapeDayOfTheWeek.type === "validate" && (
                          <div>
                            Make sure to select a day when scraping weekly.
                          </div>
                        )}
                    </span>
                  </div>
                </div>
              )}
              {/*START  GENERATE DOMAINS INPUT */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label pt-0">
                  Domains to track:
                  <br />
                  <small>[Up to 5]</small>
                </label>
                <div className="col-sm-9">
                  <ul>
                    {fields.slice(0, 5).map((item, index) => {
                      return (
                        <li key={item.id}>
                          <div className="row pt-2">
                            <div className="col-sm-9">
                              <Controller
                                as={<input />}
                                className="form-control"
                                type="url"
                                name={`domainsToTrack[${index}]`}
                                control={control}
                                defaultValue={item.domainName} // make sure to set up defaultValue
                              />
                            </div>
                            <div className="col-sm-3">
                              {index >= 4 ? null : (
                                <button
                                  className="btn btn-primary mr-1"
                                  type="button"
                                  onClick={() => {
                                    append();
                                  }}
                                >
                                  +{" "}
                                </button>
                              )}

                              {index === 0 ? null : (
                                <button
                                  className="btn btn-light"
                                  type="button"
                                  onClick={() => remove(index)}
                                >
                                  -
                                </button>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {/*END GENERATE DOMAINS INPUT */}

              <div className="form-group row">
                <label
                  htmlFor="keywordsToTrackRaw"
                  className="col-sm-3 col-form-label"
                >
                  Keywords to track
                  <br />
                  <small>[one keyword per row]</small>
                </label>
                <div className="col-sm-9">
                  <textarea
                    id="keywordsToTrackRaw"
                    name="keywordsToTrackRaw"
                    className="form-control"
                    rows="5"
                    cols="5"
                    placeholder="SEO Consulting Amsterdam"
                    defaultValue=""
                    ref={register({
                      required: true,
                      minLength: 3,
                    })}
                  />
                  <span style={errorStyle}>
                    {errors.keywordsToTrackRaw &&
                      "The project needs at least one keyword to track."}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="runNow" className="col-sm-3 col-form-label">
                  Run a scrape now?
                </label>
                <div className="col-sm-9">
                  <label className="d-block" htmlFor="runNow">
                    <input
                      className="checkbox_animated"
                      id="runNow"
                      name="runScrapeNow"
                      type="checkbox"
                      ref={register}
                    />
                    Yes please
                  </label>
                </div>
              </div>

              {stateRunScrapeNow && (
                <div className="form-group row">
                  <label
                    htmlFor="acknowledgeRun"
                    className="col-sm-3 col-form-label"
                  >
                    Please acknowledge
                  </label>
                  <div className="col-sm-9">
                    <label className="d-block" htmlFor="acknowledgeRun">
                      <input
                        className="checkbox_animated"
                        id="acknowledgeRun"
                        name="acknowledgeRun"
                        type="checkbox"
                        ref={register({
                          validate: (value) => value === stateRunScrapeNow,
                        })}
                      />
                      Yes with run now marked I Understand --xx-- credits will
                      be deducted from my account.
                    </label>
                    <span style={errorStyle}>
                      {errors.acknowledgeRun &&
                        errors.acknowledgeRun.type === "validate" && (
                          <div>
                            This has to be acknowledged when you want to run a
                            scrape immediately.
                          </div>
                        )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="col-sm-9 offset-sm-3">
            {submitting ? (
              <span>
                <div className="dotted-animation">
                  <span className="animate-circle"></span>
                  <span className="main-circle"></span>
                </div>{" "}
                Creating your new project...{" "}
              </span>
            ) : (
              <Fragment>
                <button
                  className="btn btn-primary mr-1"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </button>
                <button className="btn btn-light" type="reset">
                  {" "}
                  Reset{" "}
                </button>
              </Fragment>
            )}
          </div>
        </div>
      </form>
      {/*<ReactQueryDevtools initialIsOpen={false} /> */}
      <ToastContainer />
    </div>
  );
};

export default NewProjectForm;
