import React from "react";
import Header from "./common/header-component/header";
import Sidebar from "./common/sidebar-component/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./common/loader";
// import ThemeCustomizer from "./common/theme-customizer";
// <ThemeCustomizer />

// Data imports
// import { AuthContext } from "../data/context/auth.context";
// import fireconf from "../data/firebase/config";
// import { useAuth } from "../data/hooks/useAuth.hook";

const AppLayout = ({ children }) => {
  return (
    <div>
      <div>
        <Loader />
        <div className="page-wrapper">
          <div className="page-body-wrapper">
            <Header />
            <Sidebar />
            <RightSidebar />
            <div className="page-body">{children}</div>
            <Footer />
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AppLayout;
