import React from "react";

const KpiCard = (props) => {
  const mainKpi = props.mainKpi;
  const footerText = props.footerText;

  return (
    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body">
          <div className="project-widgets text-center">
            <h2 className="font-primary counter">{mainKpi}</h2>
          </div>
        </div>
        <div className="card-footer project-footer">
          <h6 className="mb-0 align-right">
            <small>{footerText}</small>
            <span className="counter ml-1"></span>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default KpiCard;
