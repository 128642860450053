import React from "react";
import { Row, Col, Button, Input, CustomInput } from "reactstrap";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { Filter, DefaultColumnFilter } from "./Filter.component";

const DataCard = ({ columns, data, cardName, noPagination }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // if no usePagination it's rows instead of page. Make sure to change in the code too.
    prepareRow,
    // below props related to usePagination hook
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5>{cardName}</h5>
      </div>
      <div className="card-body">
        <div className="user-status table-responsive  table-hover">
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <Filter column={column} />
                      <div {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {noPagination ? null : (
            <Row
              style={{
                maxWidth: 1000,
                margin: "15px auto",
                textAlign: "center",
              }}
            >
              <Col md={3}>
                <Button
                  color="primary"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </Button>
                <Button
                  color="primary"
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </Button>
              </Col>
              <Col md={2} style={{ marginTop: 7 }}>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </Col>
              <Col md={2}>
                <Input
                  type="number"
                  min={1}
                  style={{ width: 70 }}
                  max={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  onChange={onChangeInInput}
                />
              </Col>
              <Col md={2}>
                <CustomInput
                  type="select"
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </CustomInput>
              </Col>
              <Col md={3}>
                <Button
                  color="primary"
                  onClick={nextPage}
                  disabled={!canNextPage}
                >
                  {">"}
                </Button>
                <Button
                  color="primary"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataCard;
