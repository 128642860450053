import React, { Fragment, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "react-query";
import { firebaseFunctions } from "../../data/firebase/config";

const ProjectEditModal = ({
  settingsModal,
  setSettingsModal,
  projectName,
  projectID,
  projectMetaData,
  projectScrapeDetails,
}) => {
  const [scrapeLocationValue, setScrapeLocationValue] = useState(
    projectMetaData.location
  );
  const [scrapeLanguageValue, setScrapeLanguageValue] = useState(
    projectMetaData.language
  );

  const toggleSettingsModal = () => {
    setSettingsModal(!settingsModal);
  };

  const fetchLocations = async () => {
    const res = await fetch(
      "https://europe-west1-reading-rooster-tracker.cloudfunctions.net/fetchScrapeLocations"
    );
    console.log("Actually fetched location the data from cloud function");
    return res.json();
  };

  const { data: locData, status: locStatus } = useQuery(
    "locations",
    fetchLocations,
    {
      staleTime: 1000 * 60 * 60 * 24, // fresh time is 24 hours one hour as this data barely refreshes
      cacheTime: 1000 * 60 * 60 * 24, // cache time is 24 hours as this data barely refreshes
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: () =>
        toast.error("Something went wrong. Please reload the page."),
    }
  );

  const fetchLanguages = async () => {
    const res = await fetch(
      "https://us-central1-reading-rooster-tracker.cloudfunctions.net/fetchScrapeLanguages"
    );
    console.log("Actually fetched language data from cloud function");
    return res.json();
  };

  const { data: lanData, status: lanStatus } = useQuery(
    "languages",
    fetchLanguages,
    {
      staleTime: 1000 * 60 * 60 * 24, // fresh time is 24 hours one hour as this data barely refreshes
      cacheTime: 1000 * 60 * 60 * 24, // cache time is 24 hours as this data barely refreshes
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: () =>
        toast.error("Something went wrong. Please reload the page."),
    }
  );

  const [submitting, setSubmitting] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm({});

  const watchScrapeFrequency = watch(
    "scrapeFrequency",
    projectScrapeDetails.scrapeFrequency
  );

  const onSubmit = (data) => {
    setSubmitting(true);

    data.projectID = projectID;
    data.scrapeLocationValue = scrapeLocationValue;
    data.scrapeLanguageValue = scrapeLanguageValue;

    console.log("data", data);

    const editProject = firebaseFunctions.httpsCallable("editProjectDetails");

    editProject(data)
      .then((result) => {
        toast.success(result.data);
        toggleSettingsModal();
        setSubmitting(false);
      })
      .catch((error) => {
        console.error("Error", error.message);
        toggleSettingsModal();
        toast.error(error);
      });
  };

  const errorStyle = {
    color: "#ff5370",
  };

  return (
    <Fragment>
      <Modal isOpen={settingsModal} toggle={toggleSettingsModal}>
        <ModalHeader toggle={toggleSettingsModal}>
          Change project settings
        </ModalHeader>
        <ModalBody>
          <p>
            <small>
              Current settings are filled in. Changes will <strong>not</strong>{" "}
              effect previously scraped data.
            </small>
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <div className="form-group row">
                <label
                  htmlFor="projectName"
                  className="col-sm-3 col-form-label"
                >
                  Project Name:
                </label>
                <div className="col-sm-9">
                  <input
                    id="projectName"
                    name="projectName"
                    className="form-control"
                    type="text"
                    defaultValue={projectName}
                    ref={register({
                      required: {
                        value: true,
                        message: "The project really needs a name :)",
                      },
                      pattern: {
                        value: /^[A-Za-z0-9- ]+$/i,
                        message: "Alphabetical characters and numbers only.",
                      },
                      minLength: {
                        value: 3,
                        message:
                          "Please make sure the project has a name of at least 3 characters.",
                      },
                      maxLength: {
                        value: 35,
                        message:
                          "Do you really need more than 35 charachters for your project name?",
                      },
                    })}
                  />
                  <span style={errorStyle}>
                    {errors.projectName && errors.projectName.message}
                  </span>
                  <div className="valid-feedback">Looks good!</div>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="scrapeLocation"
                  className="col-sm-3 col-form-label"
                >
                  Scrape Location:
                </label>
                <div className="col-sm-9">
                  <select
                    id="scrapeLocation"
                    name="scrapeLocation"
                    defaultValue={projectMetaData.location_code}
                    className="form-control"
                    onChange={(e) => {
                      let index = e.nativeEvent.target.selectedIndex;
                      let text = e.nativeEvent.target[index].text;
                      setScrapeLocationValue(text);
                    }}
                    ref={register({ required: true })}
                  >
                    {locStatus === "loading" && (
                      <option value="loading">Loading locations...</option>
                    )}

                    {locStatus === "error" && (
                      <Fragment>
                        <option value="loading">
                          Please reload the page...
                        </option>
                      </Fragment>
                    )}

                    {locStatus === "success" && (
                      <Fragment>
                        {
                          (locData.sort((a, b) => {
                            let locA = a.location_name.toLowerCase();
                            let locB = b.location_name.toLowerCase();
                            if (locA < locB) {
                              return -1;
                            }
                            if (locA > locB) {
                              return 1;
                            }
                            return 0;
                          }),
                          locData.map((location) => (
                            <option
                              key={location.location_code}
                              value={location.location_code}
                            >
                              {location.location_name}
                            </option>
                          )))
                        }
                      </Fragment>
                    )}
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="scrapeLocationLanguage"
                  className="col-sm-3 col-form-label"
                >
                  Scrape Language:
                </label>
                <div className="col-sm-9">
                  <select
                    id="scrapeLocationLanguage"
                    name="scrapeLocationLanguage"
                    defaultValue={projectMetaData.language_code}
                    className="form-control"
                    onChange={(e) => {
                      let index = e.nativeEvent.target.selectedIndex;
                      let text = e.nativeEvent.target[index].text;
                      setScrapeLanguageValue(text);
                    }}
                    ref={register({ required: true })}
                  >
                    {lanStatus === "loading" && (
                      <option value="loading">Loading languages...</option>
                    )}

                    {lanStatus === "error" && (
                      <Fragment>
                        <option value="loading">
                          Please reload the page...
                        </option>
                      </Fragment>
                    )}

                    {lanStatus === "success" && (
                      <Fragment>
                        {
                          (lanData.sort((a, b) => {
                            let locA = a.language_name.toLowerCase();
                            let locB = b.language_name.toLowerCase();
                            if (locA < locB) {
                              return -1;
                            }
                            if (locA > locB) {
                              return 1;
                            }
                            return 0;
                          }),
                          lanData.map((language) => (
                            <option
                              key={language.language_code}
                              value={language.language_code}
                            >
                              {language.language_name}
                            </option>
                          )))
                        }
                      </Fragment>
                    )}
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="scrapeDevice"
                  className="col-sm-3 col-form-label"
                >
                  Device:
                </label>
                <div className="col-sm-9">
                  <select
                    id="scrapeDevice"
                    name="scrapeDevice"
                    defaultValue={projectMetaData.device}
                    className="form-control"
                    ref={register({ required: true })}
                  >
                    <option value="mobile">Mobile</option>
                    <option value="desktop">Desktop</option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="deviceOS" className="col-sm-3 col-form-label">
                  Device OS:
                </label>
                <div className="col-sm-9">
                  <select
                    id="deviceOS"
                    name="deviceOS"
                    defaultValue={projectMetaData.os}
                    className="form-control"
                    ref={register({ required: true })}
                  >
                    <option value="windows">Windows</option>
                    <option value="macos">Mac OS</option>
                    <option value="ios">iOS</option>
                    <option value="android">Android</option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="scrapeFrequency"
                  className="col-sm-3 col-form-label"
                >
                  Scrape Frequency:
                </label>
                <div className="col-sm-9">
                  <select
                    id="scrapeFrequency"
                    name="scrapeFrequency"
                    defaultValue={projectScrapeDetails.scrapeFrequency}
                    className="form-control"
                    ref={register({ required: true })}
                  >
                    <option value="manually">Manually</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>
              </div>

              {watchScrapeFrequency === "weekly" && (
                <div className="form-group row">
                  <label
                    htmlFor="scrapeDayOfTheWeek"
                    className="col-sm-3 col-form-label"
                  >
                    Select which day of the week to get the data:
                  </label>
                  <div className="col-sm-9">
                    <select
                      id="scrapeDayOfTheWeek"
                      name="scrapeDayOfTheWeek"
                      defaultValue={projectScrapeDetails.scrapeDayOfTheWeek}
                      className="form-control"
                      ref={register({
                        validate: (value) => {
                          if (watchScrapeFrequency === "weekly") {
                            return true;
                          }
                        },
                      })}
                    >
                      <option value="monday">Monday</option>
                      <option value="tuesday">Tuesday</option>
                      <option value="wednesday">Wednesday</option>
                      <option value="thursday">Thursday</option>
                      <option value="friday">Friday</option>
                      <option value="saturday">Saterday</option>
                      <option value="sunday">Sunday</option>
                    </select>
                    <span style={errorStyle}>
                      {errors.scrapeDayOfTheWeek &&
                        errors.scrapeDayOfTheWeek.type === "validate" && (
                          <div>
                            Make sure to select a day when scraping weekly.
                          </div>
                        )}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <ModalFooter>
              {submitting ? (
                "Updating..."
              ) : (
                <>
                  <button
                    className="btn btn-primary mr-1"
                    type="submit"
                    disabled={submitting}
                  >
                    Save changes
                  </button>
                  <Button color="secondary" onClick={toggleSettingsModal}>
                    Cancel
                  </Button>
                </>
              )}
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </Fragment>
  );
};

export default ProjectEditModal;
