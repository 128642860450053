import React, { Fragment } from "react";
import NewProjectForm from "../../components/forms/newproject.form.component";
import Breadcrumb from "../../components/common/breadcrumb";

const ProjectNew = () => {
  return (
    <Fragment>
      <Breadcrumb title="Create new project" parent="Projects" noTitle />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <NewProjectForm />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProjectNew;
