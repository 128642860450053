import React, { Fragment } from "react";

import { User, Settings, LogOut } from "react-feather";

// data & firebase action imports in useAuth
// import { useRequireAuth } from "../../../data/hooks/useRequireAuth.hook";
import { useAuth } from "../../../data/hooks/useAuth.hook";
import { useRouter } from "../../../data/hooks/useRouter.hook";

const UserMenu = () => {
  const auth = useAuth();
  const router = useRouter();

  return (
    <Fragment>
      <li className={auth.userProfile ? "onhover-dropdown" : null}>
        {/*console.log("check: ", auth.userProfile)*/}
        <div className="media align-items-center">
          <a className="txt-dark" href="#javascript">
            <h6>
              {auth.userProfile
                ? "Hi " + auth.userProfile.firstName
                : "Please Login"}
            </h6>
          </a>
          {/*<div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
  </div>*/}
        </div>
        <ul className="profile-dropdown onhover-show-div p-10 profile-dropdown-hover">
          <li>
            <a href="#javascript">
              <User />
              Edit Profile
            </a>
          </li>
          <li>
            <a href="#javascript">
              <Settings />
              Settings
            </a>
          </li>
          <li
            onClick={(e) => {
              auth.signout();
              router.push("/login");
            }}
          >
            <a href="#javascript">
              <LogOut /> Log out
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
