import { useState, useEffect } from "react";
import { fdb } from "../firebase/config";
import { useAuth } from "../hooks/useAuth.hook";

const useFirestore = (collection) => {
  const auth = useAuth();
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    if (auth.userProfile) {
      fdb
        .collection(collection)
        .where("clientID", "==", auth.userProfile.organizationID)
        .get()
        .then((snap) => {
          let documents = [];
          snap.forEach((doc) => {
            documents.push({ ...doc.data(), projectID: doc.id });
          });
          setDocs(documents);
          console.log("loaded firebase data as requested");
        });
    }
    return;
  }, [collection, auth]);
  return { docs };
};

export default useFirestore;
